<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <v-stepper class="mb-5" v-model="e1">
          <v-stepper-header>
            <v-stepper-step editable :complete="e1 > 1" step="1">
              Datos del Catálogo
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              v-if="statusData == 1"
              editable
              :complete="e1 > 2"
              step="2"
            >
              Portada del Catálogo
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step v-if="statusData == 1" editable step="3">
              PDF's del Catálogo
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card flat class="mb-12">
                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      :items="itemsPais"
                      item-text="name"
                      item-value="id"
                      label="País de Importación"
                      @change="loadCampanasS()"
                      v-model="id_pais"
                      @keypress="validaDatos()"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      :items="itemsCampana"
                      item-text="name"
                      item-value="id"
                      label="Campaña"
                      v-model="id_campana"
                      @change="loadCatalogosS()"
                      @keypress="validaDatos()"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      :items="itemsProveedor"
                      item-text="name"
                      item-value="id"
                      label="Proveedor"
                      v-model="id_proveedor"
                      @keypress="validaDatos()"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-autocomplete
                      :items="itemsRubros"
                      item-text="name"
                      item-value="id"
                      label="Rubro"
                      v-model="id_rubro"
                      @keypress="validaDatos()"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="1">
                    <v-btn
                      @click="dialog = true"
                      elevation="0"
                      color="primary"
                      fab
                      ><v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      label="Nombre del Catálogo"
                      @keyup="validaDatos()"
                      v-model="name"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field label="Descripción" v-model="description">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="statusData == 2">
                  <v-col cols="12" md="6">
                    <label style="font-weight: bold" class="btn btn-primary">
                      <i class="fa fa-folder-open-o" aria-hidden="true"></i
                      >Seleccionar Portada
                      <input
                        type="file"
                        @change="viewPhoto"
                        id="fotos"
                        ref="file"
                        dense
                        dark
                        placeholder="Subir portada"
                      />
                    </label>
                  </v-col>
                  <v-col cols="12" md="6">
                    <cropper
                      ref="cropper"
                      resizeImage="true"
                      :src="url"
                      @change="change"
                    />
                  </v-col>

                  <v-col cols="6" md="6">
                    <v-btn block color="error" @click="cleanData()" rounded
                      >Cancelar</v-btn
                    >
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-btn
                      block
                      v-if="statusData == 1"
                      color="success"
                      @click="addData()"
                      rounded
                      >Agregar</v-btn
                    >
                    <v-btn
                      block
                      v-if="statusData == 2"
                      color="success"
                      @click="updateData()"
                      rounded
                      >Actualizar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card>

              <v-btn v-if="statusData == 1" color="primary" @click="e1 = 2">
                Siguiente
              </v-btn>

              <v-btn v-if="statusData == 1" text>
                Cancelar
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card flat class="mb-12">
                <v-row>
                  <v-col cols="12" md="6">
                    <label style="font-weight: bold" class="btn btn-primary">
                      <i class="fa fa-folder-open-o" aria-hidden="true"></i
                      >Seleccionar Portada
                      <input
                        type="file"
                        @change="viewPhoto"
                        id="fotos"
                        ref="file"
                        dense
                        dark
                        placeholder="Subir portada"
                      />
                    </label>
                  </v-col>
                  <v-col cols="12" md="6">
                    <cropper
                      ref="cropper"
                      resizeImage="true"
                      :src="url"
                      @change="change"
                    />
                  </v-col>
                </v-row>
              </v-card>

              <v-btn color="primary" @click="e1 = 3">
                Siguiente
              </v-btn>

              <v-btn text>
                Cancelar
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card flat class="mb-12">
                <v-row>
                  <!-- <v-col class="mb-5" cols="12">
                    <h2 class="mb-2 mt-2">Archivos</h2>
                    <v-divider></v-divider>
                  </v-col> -->

                  <v-col
                    v-if="statusData == 1 && statusArticulos == false"
                    cols="12"
                    md="6"
                  >
                    <label style="font-weight: bold" class="btn btn-primary">
                      <i class="fa fa-folder-open-o" aria-hidden="true"></i
                      >Seleccionar PDF Sin Precios
                      <input
                        id="file1"
                        @change="verifyFile1"
                        type="file"
                        accept=".pdf"
                        ref="file1"
                        name="file"
                      />
                    </label>
                  </v-col>
                  <v-col
                    v-if="statusData == 1 && statusArticulos == false"
                    cols="12"
                    md="6"
                  >
                    <label style="font-weight: bold" class="btn btn-primary">
                      <i class="fa fa-folder-open-o" aria-hidden="true"></i
                      >Seleccionar PDF Con Precios
                      <input
                        id="file2"
                        @change="verifyFile2"
                        type="file"
                        accept=".pdf"
                        ref="file2"
                        name="file"
                      />
                    </label>
                  </v-col>
                  <v-col v-if="statusData == 1" cols="12" md="6">
                    <img
                      v-if="statusFile1"
                      ref="pdf1"
                      height="100"
                      :src="pdf"
                      class="mx-auto"
                      alt=""
                    />
                  </v-col>
                  <v-col v-if="statusData == 1" cols="12" md="6">
                    <img
                      v-if="statusFile2"
                      ref="pdf2"
                      height="100"
                      class="mx-auto"
                      :src="pdf"
                      alt=""
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-switch
                      label="Estado del Catálogo"
                      v-model="status"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-switch
                      label="No tengo PDF"
                      v-model="statusArticulos"
                      @change="changeStatus()"
                    ></v-switch>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-btn block color="error" @click="cleanData()" rounded
                      >Cancelar</v-btn
                    >
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-btn
                      block
                      v-if="statusData == 1"
                      color="success"
                      @click="addData()"
                      rounded
                      >Agregar</v-btn
                    >
                    <v-btn
                      block
                      v-if="statusData == 2"
                      color="success"
                      @click="updateData()"
                      rounded
                      >Actualizar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card>

              <v-btn color="primary" @click="e1 = 1">
                Sguiente
              </v-btn>

              <v-btn text>
                Cancelar
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
      <v-col v-if="statusData == 1">
        <h1>Validaciones Obligatorias</h1>
        <v-list two-line>
          <v-list-item
            dark
            :class="statusDatos ? 'validacionok' : 'validacionerror'"
          >
            <v-list-item-content>
              <v-list-item-title>Datos del Catálogo</v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon>
              <v-icon v-if="statusDatos" x-large>mdi-check-circle</v-icon>
              <v-icon v-if="!statusDatos" x-large>mdi-close-circle</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            dark
            :class="statusPortadaCata ? 'validacionok' : 'validacionerror'"
          >
            <v-list-item-content>
              <v-list-item-title>Portada del Catálogo</v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon>
              <v-icon v-if="statusPortadaCata" x-large>mdi-check-circle</v-icon>
              <v-icon v-if="!statusPortadaCata" x-large
                >mdi-close-circle</v-icon
              >
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            dark
            :class="statusPDF ? 'validacionok' : 'validacionerror'"
          >
            <v-list-item-content>
              <v-list-item-title>PDF's del Catálogo</v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon>
              <v-icon v-if="statusPDF" x-large>mdi-check-circle</v-icon>
              <v-icon v-if="!statusPDF" x-large>mdi-close-circle</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <v-card flat>
          <v-card-title>
            Lista

            <v-switch
              class="ml-5"
              label="Sin PDF's"
              v-model="statusArticulosTwo"
            ></v-switch>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="itemsCatalogos"
            :search="search"
            v-if="!statusArticulosTwo"
          >
            <template v-slot:item.portada="{ item }">
              <img
                height="50"
                style="border-radius: 5px"
                :src="item.portada"
                alt=""
              />
            </template>
            <template v-slot:item.statusSp="{ item }">
              <v-chip :color="getstatusSp(item.statusSp)" dark>
                {{ item.statusSp }}
              </v-chip>
              <v-btn
                fab
                x-small
                :href="`https://point.qreport.site/files/${item.ruta_sp}`"
                target="_blank"
                class="mr-2"
                flat
                elevation="0"
              >
                <v-icon small>
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>

            <template v-slot:item.statusCp="{ item }">
              <v-icon
                :color="getstatusCp(item.statusCp)"
                class="mr-2"
                large
                @click="upload(item.id, item.name)"
              >
                mdi-cloud-upload
              </v-icon>
              <v-btn
                v-if="item.statusCp == 'Ok'"
                fab
                x-small
                :href="`https://point.qreport.site/files/${item.ruta_cp}`"
                target="_blank"
                class="mr-2"
                flat
                elevation="0"
              >
                <v-icon small>
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:item.statusCo="{ item }">
              <v-icon
                :color="getstatusCo(item.statusCo)"
                class="mr-2"
                large
                @click="uploadOriginal(item.id, item.name)"
              >
                mdi-cloud-upload
              </v-icon>
              <v-btn
                v-if="item.statusCo == 'Ok'"
                fab
                x-small
                :href="`https://point.qreport.site/files/${item.ruta_co}`"
                target="_blank"
                class="mr-2"
                flat
                elevation="0"
              >
                <v-icon small>
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="loadCatalogo(item.id)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item.id)">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:item.status="{ item }">
              <v-switch
                @change="updateStatus(item.id, item.status)"
                v-model="item.status"
                dense
              ></v-switch> </template
          ></v-data-table>

          <v-data-table
            :headers="headerstwo"
            :items="itemsCatalogos"
            :search="search"
            v-if="statusArticulosTwo"
          >
            <template v-slot:item.portada="{ item }">
              <img
                height="50"
                style="border-radius: 5px"
                :src="item.portada"
                alt=""
              />
            </template>

            <template v-slot:item.articulos="{ item }">
              <v-icon large @click="openArticulos(item.id)">
                mdi-cloud-upload
              </v-icon>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="loadCatalogo(item.id)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item.id)">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:item.status="{ item }">
              <v-switch
                @change="updateStatus(item.id, item.status)"
                v-model="item.status"
                dense
              ></v-switch> </template
          ></v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog width="85%" v-model="dialogarticulos">
      <v-card>
        <v-card-title>
          Agregar Articulos
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col colos="12" md="4">
              <croppa
                v-model="myCroppa"
                :placeholder-font-size="16"
                placeholder="Subir foto del articulo"
                :prevent-white-space="true"
                :width="400"
                :height="450"
                :initial-image="art_image"
              >
              </croppa>
            </v-col>
            <v-col cols="12" md="8">
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field v-model="art_nombre" label="Nombre">
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="art_precio_compra"
                    label="Precio Compra"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="art_precio_senior"
                    label="Precio Senior"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="art_precio_junior"
                    label="Precio Junior"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field v-model="art_cantidad" label="Cantidad Mínima">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field v-model="art_modelo" label="Modelo">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field v-model="art_escala" label="Escala">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field v-model="art_genero" label="Genero">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field v-model="art_temporada" label="Temporada">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-switch
                    v-model="art_status"
                    dense
                    label="Status del Articulo"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6">
                  <v-textarea
                    v-model="art_color"
                    rows="7"
                    name="input-7-1"
                    label="Color"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="6">
                  <v-textarea
                    v-model="art_caracteristicas"
                    rows="7"
                    name="input-7-1"
                    label="Caracteristicas"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-btn
                v-if="status_art == 1"
                color="primary"
                @click="addArticulo()"
                block
                >Agregar</v-btn
              >

              <v-btn
                v-if="status_art == 2"
                color="primary"
                @click="updateArticulo()"
                block
                >Actualizar</v-btn
              >
            </v-col>
          </v-row>

          <v-data-table
            :headers="headerArticulos"
            :items="itemsArticulos"
            :search="searchArt"
            class="mt-5"
          >
            <template v-slot:item.portada="{ item }">
              <img
                height="50"
                style="border-radius: 5px"
                :src="item.portada"
                alt=""
              />
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon medium class="mr-2" @click="editItemArt(item.id)">
                mdi-pencil
              </v-icon>
              <v-icon medium @click="deleteItemArt(item.id)">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:item.image="{ item }">
              <img width="80" :src="item.image" alt="" />
            </template>
            <template v-slot:item.status="{ item }">
              <v-switch
                @change="updateStatus(item.id, item.status)"
                v-model="item.status"
                dense
              ></v-switch> </template
          ></v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogarticulos = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Agregar Rubro
        </v-card-title>

        <v-card-text>
          <v-text-field v-model="name_rubro" label="Nombre"></v-text-field>
          <v-text-field
            v-model="description_rubro"
            label="Descripción"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn color="primary" text @click="addRubro()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" width="700">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Subir PDF con Precios de {{ this.name_catalogo }}
        </v-card-title>

        <v-card-text>
          <v-col v-if="statusData == 1" cols="12" md="6">
            <label style="font-weight: bold" class="btn btn-primary">
              <i class="fa fa-folder-open-o" aria-hidden="true"></i>Seleccionar
              PDF Con Precios
              <input
                id="file2"
                @change="verifyFile3"
                type="file"
                accept=".pdf"
                ref="file2"
                name="file"
              />
            </label>
            <img
              v-if="statusFile3"
              ref="pdf2"
              height="100"
              class="mx-auto mt-5"
              :src="pdf"
              alt=""
            />
          </v-col>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog2 = false">
            Cancelar
          </v-btn>
          <v-btn color="primary" text @click="uploadData()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog4" width="700">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Subir PDF Original de {{ this.name_catalogo }}
        </v-card-title>

        <v-card-text>
          <v-col v-if="statusData == 1" cols="12" md="6">
            <label style="font-weight: bold" class="btn btn-primary">
              <i class="fa fa-folder-open-o" aria-hidden="true"></i>Seleccionar
              PDF Original
              <input
                id="file5"
                @change="verifyFile5"
                type="file"
                accept=".pdf"
                ref="file5"
                name="file"
              />
            </label>
            <img
              v-if="statusFile5"
              ref="pdf5"
              height="100"
              class="mx-auto mt-5"
              :src="pdf"
              alt=""
            />
          </v-col>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog4 = false">
            Cancelar
          </v-btn>
          <v-btn color="primary" text @click="uploadDataOriginal()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogLoad" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Subiendo Archivos
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import VueResource from "vue-resource";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import pdf from "../../../public/assets/img/pdf.svg";
import Croppa from "vue-croppa";
import "vue-croppa/dist/vue-croppa.css";
export default {
  name: "Campana",
  components: {
    Cropper,
  },
  data: () => ({
    myCroppa: {},
    e1: 1,
    dataUrl: "",
    pdf: pdf,
    statusArticulos: false,
    statusArticulosTwo: false,
    search: "",
    itemsPais: [],
    itemsProveedor: [],
    itemsRubros: [],
    itemsCampana: [],
    itemsCatalogos: [],
    itemsArticulos: [],
    name_rubro: "",
    name_catalogo: "",
    id_catalogo: "",
    description_rubro: "",
    id: "",
    id_pais: "",
    id_proveedor: "",
    id_rubro: "",
    dialog: false,
    dialog2: false,
    id_campana: "",
    name: "",
    description: "",
    status: true,
    statusData: 1,
    dialogLoad: false,
    statusFile1: false,
    statusFile2: false,
    statusFile3: false,
    url: "",
    urlstring: "",
    file: "",
    file2: "",
    file3: "",
    file4: "",
    file5: "",
    dialogarticulos: false,

    art_image: "",
    art_nombre: "",
    art_precio_senior: "",
    art_precio_junior: "",
    art_precio_compra: "",
    art_cantidad: "",
    art_modelo: "",
    art_escala: "",
    art_color: "",
    art_genero: "",
    art_temporada: "",
    art_caracteristicas: "",
    art_status: "",
    id_articulo: "",
    status_art: 1,

    statusDatos: false,
    statusPortadaCata: false,
    statusPDF: false,

    headers: [
      {
        text: "Nombre",
        value: "name",
      },
      { text: "Rubro", value: "name_rubro" },
      { text: "Portada", value: "portada" },
      { text: "Sin Precio", value: "statusSp" },
      { text: "Con Precio", value: "statusCp" },
      { text: "Original", value: "statusCo" },
      { text: "Descripción", value: "description" },
      { text: "Acciones", value: "actions" },
      { text: "Ver", value: "status" },
    ],

    headerArticulos: [
      {
        text: "Nombre",
        value: "name",
      },
      { text: "Modelo", value: "codigo" },
      { text: "Escala", value: "escala" },
      { text: "Color", value: "color" },
      { text: "Genero", value: "genero" },
      { text: "Temporada", value: "temporada" },
      { text: "Imagen", value: "image" },
      { text: "Precio de Compra", value: "precio_compra" },
      { text: "Precio Senior", value: "precio_venta" },
      { text: "Precio Junior", value: "precio_junior" },
      { text: "Top", value: "top" },
      { text: "Cantidad Mínima", value: "cantidad_minima" },
      { text: "Descripción", value: "caracteristicas" },
      { text: "Acciones", value: "actions" },
      { text: "Ver", value: "status" },
    ],

    headerstwo: [
      {
        text: "Nombre",
        value: "name",
      },
      { text: "Rubro", value: "name_rubro" },
      { text: "Descripción", value: "description" },
      { text: "Articulos", value: "articulos" },
      { text: "Acciones", value: "actions" },
      { text: "Ver", value: "status" },
    ],
  }),
  mounted() {
    this.loadPais();

    this.loadRubros();
  },
  methods: {
    getstatusPortada(statusPortada) {
      if (statusPortada == "Ok") return "green";
      else return "red";
    },
    upload(id, name) {
      this.id_catalogo = id;
      this.name_catalogo = name;

      this.dialog2 = true;
    },
    uploadCroppedImage() {
      this.myCroppa.generateBlob(
        (blob) => {
          // write code to upload the cropped image file (a file is a blob)
        },
        "image/jpeg",
        0.8
      ); // 80% compressed jpeg file
    },

    uploadOriginal(id, name) {
      this.id_catalogo = id;
      this.name_catalogo = name;

      this.dialog4 = true;
    },
    getstatusSp(statusSp) {
      if (statusSp == "Ok") return "green";
      else return "red";
    },
    getstatusCp(statusCp) {
      if (statusCp == "Ok") return "green";
      else return "red";
    },
    getstatusCo(statusCo) {
      if (statusCo == "Ok") return "green";
      else return "red";
    },
    change({ coordinates, canvas }) {
      console.log(coordinates, canvas);
    },
    viewPhoto(e) {
      this.file = e.target.files[0];
      this.url = URL.createObjectURL(this.file);
      this.validaPortada();
    },
    verifyFile1(e) {
      this.statusFile1 = true;
      this.file2 = e.target.files[0];
      this.validaPdf();
    },
    verifyFile2(e) {
      this.statusFile2 = true;
      this.file3 = e.target.files[0];
    },
    verifyFile3(e) {
      this.statusFile3 = true;
      this.file4 = e.target.files[0];
    },
    verifyFile5(e) {
      this.statusFile5 = true;
      this.file5 = e.target.files[0];
    },
    loadPais() {
      axios.get(process.env.VUE_APP_API_URL + "pais").then((result) => {
        this.itemsPais = result.data;
      });
    },
    addRubro() {
      let data = {
        name: this.name_rubro,
        description: this.description_rubro,
        status: 1,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "rubros", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.loadRubros();
            this.dialog = false;
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    loadRubros() {
      axios.get(process.env.VUE_APP_API_URL + "rubros").then((result) => {
        this.itemsRubros = result.data;
      });
    },
    loadArticulos(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "articulos/cat/" + id)
        .then((result) => {
          this.itemsArticulos = result.data;
        });
    },
    editItemArt(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "articulos/" + id)
        .then((result) => {
          this.status_art = 2;
          this.id_articulo = result.data[0].id;
          this.art_nombre = result.data[0].name;
          this.art_image = result.data[0].image;
          this.art_precio_senior = result.data[0].precio_venta;
          this.art_precio_junior = result.data[0].precio_junior;
          this.art_precio_compra = result.data[0].precio_compra;
          this.art_cantidad = result.data[0].cantidad_minima;
          this.art_escala = result.data[0].escala;
          this.art_color = result.data[0].color;
          this.art_genero = result.data[0].genero;
          this.art_temporada = result.data[0].temporada;
          this.art_modelo = result.data[0].codigo;
          this.art_status = result.data[0].status;
          this.art_caracteristicas = result.data[0].caracteristicas;
        });
    },
    loadCampanas() {
      axios.get(process.env.VUE_APP_API_URL + "campana").then((result) => {
        this.itemsCampana = result.data;
      });
    },
    loadCatalogo(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "catalogos/" + id)
        .then((result) => {
          this.url = result.data[0].portada;
          this.urlstring = result.data[0].portada;
          this.id = result.data[0].id;
          this.id_pais = result.data[0].id_pais;
          this.id_campana = result.data[0].id_campana;
          this.id_proveedor = result.data[0].id_proveedor;
          this.id_rubro = result.data[0].id_rubro;
          this.name = result.data[0].name;
          this.description = result.data[0].description;

          this.statusData = 2;
        });
    },
    loadCampanasS() {
      axios
        .get(process.env.VUE_APP_API_URL + "campana/p/" + this.id_pais)
        .then((result) => {
          this.itemsCampana = result.data;
          this.loadProveedoresS();
        });
    },
    loadCatalogosS() {
      axios
        .get(process.env.VUE_APP_API_URL + "catalogos/p/" + this.id_campana)
        .then((result) => {
          this.itemsCatalogos = result.data;
          localStorage.setItem("itemsCatalogos", JSON.stringify(result.data));
        });
    },
    loadProveedoresS() {
      axios
        .get(process.env.VUE_APP_API_URL + "proveedores/p/" + this.id_pais)
        .then((result) => {
          this.itemsProveedor = result.data;
        });
    },
    cleanArticulos() {
      this.art_image = "";
      this.art_nombre = "";
      this.art_precio_venta = "";
      this.art_precio_compra = "";
      this.art_cantidad = "";
      this.art_caracteristicas = "";
      this.art_status = "";
      this.dataUrl = "";
      this.status_art = 1;
    },
    editItem(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "campana/" + id)
        .then((result) => {
          this.id = result.data[0].id;
          this.id_pais = result.data[0].id_pais;
          this.name = result.data[0].name;
          this.description = result.data[0].description;
          this.status = result.data[0].status;
          this.statusData = 2;
        });
    },
    capture() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;

      this.urlstring = canvas.toDataURL();
    },
    addArticulo() {
      this.dataUrl = this.myCroppa.generateDataUrl("image/jpeg", 0.8);
      let data = {
        id_catalogo: this.art_id_catalogo,
        image: this.dataUrl,
        name: this.art_nombre,
        precio_compra: this.art_precio_compra,
        precio_venta: this.art_precio_venta,
        cantidad_minima: this.art_cantidad,
        caracteristicas: this.art_caracteristicas,
        status: this.art_status,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "articulos", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Articulo registrado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanArticulos();
            this.loadArticulos(this.art_id_catalogo);
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    updateArticulo() {
      this.dataUrl = this.myCroppa.generateDataUrl("image/jpeg", 0.8);
      let data = {
        id_catalogo: this.art_id_catalogo,
        name: this.art_nombre,
        precio_compra: this.art_precio_compra,
        precio_venta: this.art_precio_venta,
        cantidad_minima: this.art_cantidad,
        caracteristicas: this.art_caracteristicas,
        status: this.art_status,
      };
      axios
        .put(
          process.env.VUE_APP_API_URL + "articulos/" + this.id_articulo,
          data
        )
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Articulo actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanArticulos();
            this.loadArticulos(this.art_id_catalogo);
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    addData() {
      if (
        this.statusDatos == true &&
        this.statusPortadaCata == true &&
        this.statusPDF == true
      ) {
        this.dialogLoad = true;
        this.capture();
        const formData = new FormData();

        formData.append("id_campana", this.id_campana);
        formData.append("id_proveedor", this.id_proveedor);
        formData.append("id_rubro", this.id_rubro);
        formData.append("name", this.name);
        formData.append("description", this.description);
        formData.append("portada", this.urlstring);
        formData.append("file", this.file2);
        formData.append("file", this.file3);

        formData.append("status", 1);

        axios
          .post(process.env.VUE_APP_API_URL + "catalogos", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((result) => {
            console.log(result.data);
            if (result.data.boolean == true) {
              this.dialogLoad = false;

              Swal.fire({
                position: "center",
                icon: "success",
                title: "Catálogo registrado con éxito",
                showConfirmButton: true,
                timer: 5000,
              });

              this.loadCatalogosS();
              this.cleanData();
            } else {
              this.dialogLoad = false;

              Swal.fire({
                position: "center",
                icon: "error",
                title: "Oops...",
                text: result.data.error.sqlMessage,
                showConfirmButton: true,
                timer: 8000,
              });
            }
          });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Oops...",
          text: "Revise las validaciones obligatorias",
          showConfirmButton: true,
          timer: 8000,
        });
      }
    },

    openArticulos(id_catalogo) {
      this.art_id_catalogo = id_catalogo;
      this.dialogarticulos = true;
      this.loadArticulos(id_catalogo);
    },
    updateData() {
      this.dialogLoad = true;
      this.capture();
      const formData = new FormData();

      formData.append("id_campana", this.id_campana);
      formData.append("id_proveedor", this.id_proveedor);
      formData.append("id_rubro", this.id_rubro);
      formData.append("name", this.name);
      formData.append("description", this.description);
      formData.append("portada", this.urlstring);

      formData.append("status", 1);

      axios
        .put(process.env.VUE_APP_API_URL + "catalogos/" + this.id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.dialogLoad = false;

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Catálogo actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });

            this.loadCatalogosS();
            this.cleanData();
          } else {
            this.dialogLoad = false;

            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },

    updateStatus(id, estado) {
      const formData = new FormData();

      if (estado == true) {
        formData.append("status", 1);
      } else if (estado == false) {
        formData.append("status", 0);
      }

      axios
        .put(process.env.VUE_APP_API_URL + "catalogos/status/" + id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.dialogLoad = false;
            this.loadCatalogosS();
          } else {
            this.dialogLoad = false;

            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    validaDatos() {
      if (
        this.id_pais != "" &&
        this.id_campana != "" &&
        this.id_proveedor != "" &&
        this.id_rubro != "" &&
        this.name != ""
      ) {
        this.statusDatos = true;
      } else {
        this.statusDatos = false;
      }
    },
    validaPortada() {
      if (this.url != "") {
        this.statusPortadaCata = true;
      } else {
        this.statusPortadaCata = false;
      }
    },
    validaPdf() {
      if (this.file2 != null) {
        this.statusPDF = true;
      } else {
        this.statusPDF = false;
      }
    },
    changeStatus() {
      this.statusPDF = this.statusArticulos;
    },
    sumField(key) {
      // sum data in give key (property)
      return this.desserts.reduce((a, b) => a + (b[key] || 0), 0);
    },
    uploadData() {
      this.dialogLoad = true;

      const formData = new FormData();

      formData.append("file", this.file4);

      axios
        .put(
          process.env.VUE_APP_API_URL + "catalogos/price/" + this.id_catalogo,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.dialogLoad = false;

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Catálogo cargado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.dialog2 = false;
            this.loadCatalogosS();
            this.cleanData();
          } else {
            this.dialogLoad = false;

            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    uploadDataOriginal() {
      this.dialogLoad = true;

      const formData = new FormData();

      formData.append("file", this.file5);

      axios
        .put(
          process.env.VUE_APP_API_URL +
            "catalogos/original/" +
            this.id_catalogo,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            this.dialogLoad = false;

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Catálogo original cargado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.dialog2 = false;
            this.loadCatalogosS();
            this.cleanData();
          } else {
            this.dialogLoad = false;

            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    deleteItem(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminado, el catálogo no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(process.env.VUE_APP_API_URL + "catalogos/" + id)
            .then((result) => {
              console.log(result.data);
              if (result.data.boolean == true) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Catálogo eliminado con éxito",
                  showConfirmButton: true,
                  timer: 5000,
                });
                this.loadCatalogosS();
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: "Oops...",
                  text: result.data.error.sqlMessage,
                  showConfirmButton: true,
                  timer: 8000,
                });
              }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "El catálogo no fue eliminado", "error");
        }
      });
    },

    deleteItemArt(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminado, el articulo no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(process.env.VUE_APP_API_URL + "articulos/" + id)
            .then((result) => {
              console.log(result.data);
              if (result.data.boolean == true) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Articulo eliminado con éxito",
                  showConfirmButton: true,
                  timer: 5000,
                });
                this.loadArticulos(this.art_id_catalogo);
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: "Oops...",
                  text: result.data.error.sqlMessage,
                  showConfirmButton: true,
                  timer: 8000,
                });
              }
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "El articulo no fue eliminado", "error");
        }
      });
    },

    cleanData() {
      this.id_rubro = "";
      this.name = "";
      this.description = "";
      this.statusFile1 = false;
      this.statusFile2 = false;
      this.url = "";
      this.statusData = 1;
      this.statusDatos = false;
      this.statusPortadaCata = false;
      this.statusPDF = false;
      const input = this.$refs.file;
      input.type = "text";
      input.type = "file";
      const input1 = this.$refs.file1;
      input1.type = "text";
      input1.type = "file";
      const input2 = this.$refs.file2;
      input2.type = "text";
      input2.type = "file";
    },
  },
};
</script>
<style>
.validacionok {
  border: solid 2px #5ab55e;
  border-radius: 5px;
  margin-top: 5px;
  background: #5ab55e;
}

.validacionerror {
  border: solid 2px #f44336;
  border-radius: 5px;
  margin-top: 5px;
  background: #f44336;
}
</style>
